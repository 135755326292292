/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  margin: 0;
  padding: 0;
  font-family: Literata;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.audio {
  width:100%;
}

.audio audio {
  margin: 0 auto;
  display: table;
}
